/* You can add global styles to this file, and also import other style files */
@import './app/mat-table-responsive.directive.scss';
@import '../node_modules/angular-calendar/css/angular-calendar.css';

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

// Copied fom here: https://fireship.io/lessons/angular-firebase-storage-uploads-multi/

.STL-uploader {
    width: 50%;
    .dropzone {
        width: 100%;
    }
}

.dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 256px;
    font-weight: 200;
    height: 256px;
    border: 2px solid #2596be;
    border-radius: 5px;
    background: white;
    margin: 0.5rem;
    position: relative;

    &.hovering {
        background-color: lightblue;
        color: #dadada !important;
    }

    .file-label {
        font-size: 1.2em;
    }

    img {
        max-width: calc(256px - 1rem);
        max-height: calc(256px - 1rem);
    }
}

progress::-webkit-progress-value {
    transition: width 0.1s ease;
}
